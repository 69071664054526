import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import uuid from 'react-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../layouts/DefaultLayout'
import Date from '../components/Date'

const ShareComponent = ({ className = '' }) => {
  return (
    <ul className={`grid grid-cols-3 gap-x-5 text-gray-500 ${className}`}>
      <li>
        <a href="/#">
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </a>
      </li>
      <li>
        <a href="/#" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
      </li>
      <li>
        <a href="/#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </a>
      </li>
    </ul>
  )
}

export default function Template({ pageContext }) {
  const { t } = useTranslation()
  const {
    title,
    slug,
    content,
    date,
    language,
    categories,
    translations,
    relatives,
  } = pageContext

  const rtl = language.code === 'AR' ? 'rtl' : ''
  const lang = language.code.toLowerCase()
  const related = (relatives?.nodes ?? []).map((post) => ({
    ...post,
    link: `/${lang}/posts/${post.slug}`,
  }))

  return (
    <DefaultLayout>
      <article
        className={`container mt-24 flex flex-col items-center px-5 xl:px-0 xl:mx-auto ${rtl}`}
      >
        <h1 className="text-center text-2xl xl:text-6xl font-bold leading-tight mb-5">
          {title}
        </h1>
        <div className="text-gray-500 mb-3">
          <Date locale={lang}>{date}</Date>
        </div>
        <ShareComponent className="mb-6" />
        <div
          className="prose lg:prose-xl flex flex-col space-y-5 text-xl mb-10"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </article>
      {(related.length && (
        <div
          className={`container px-5 xl:px-0 xl:mx-auto flex flex-col space-y-3 mb-5 ${rtl}`}
        >
          <div className="text-green-theme text-2xl font-bold">
            {t('related', { lng: lang })}
          </div>
          <hr />
          <div className="flex flex-col xl:flex-row content-between space-y-5 xl:space-y-0 xl:space-x-5">
            {related.map((post) => (
              <article key={uuid()} className="flex-1">
                <h3 className="text-lg font-bold mb-1">
                  <Link to={post.link}>{post.title}</Link>
                </h3>
                <div className="text-gray-500">
                  <Date>{post.date}</Date>
                </div>
              </article>
            ))}
          </div>
        </div>
      )) ||
        null}
    </DefaultLayout>
  )
}
